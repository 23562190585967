import { Action, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { AttachmentListItem } from './attachment-table/attachment-table.component';
import {
  CreateAssetAttachment,
  DownloadAssetAttachment,
  RemoveAssetAttachment,
  SetAssetAttachments,
  UpdateAssetAttachment
} from './asset-attachment.actions';
import { AssetAttachmentClient } from '../clients/apiClients';
import { tap } from 'rxjs';

export class AssetAttachmentStateModel {
  attachments: AttachmentListItem[] | null = null;
  attachmentsLoading = false;
}

@State<AssetAttachmentStateModel>({
  name: 'assetAttachment',
  defaults: {
    attachments: null,
    attachmentsLoading: false
  }
})
@Injectable()
export class AssetAttachmentState {
  assetAttachmentClient = inject(AssetAttachmentClient);

  @Action(CreateAssetAttachment)
  createAssetAttachment(
    ctx: StateContext<AssetAttachmentStateModel>,
    action: CreateAssetAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.assetAttachmentClient
      .createAssetAttachment(
        action.assetId,
        action.description,
        this.mapFile(action.file)
      )
      .pipe(
        tap((createdAttachment) => {
          const attachments = ctx.getState().attachments;
          if (!attachments) {
            ctx.patchState({
              attachmentsLoading: false
            });
            return;
          }
          ctx.patchState({
            attachments: [...attachments, createdAttachment],
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(SetAssetAttachments)
  setAssetAttachments(ctx: any, action: SetAssetAttachments) {
    ctx.patchState({ attachments: action.attachments });
  }

  @Action(RemoveAssetAttachment)
  removeAssetAttachment(
    ctx: StateContext<AssetAttachmentStateModel>,
    action: RemoveAssetAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.assetAttachmentClient
      .deleteAssetAttachment(action.assetId, action.attachmentId)
      .pipe(
        tap(() => {
          const attachments = ctx.getState().attachments;
          if (!attachments) {
            ctx.patchState({
              attachmentsLoading: false
            });
            return;
          }
          const updatedAttachments = attachments.filter(
            (a: AttachmentListItem) => a.id !== action.attachmentId
          );
          ctx.patchState({
            attachments: updatedAttachments,
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(UpdateAssetAttachment)
  updateAssetAttachment(
    ctx: StateContext<AssetAttachmentStateModel>,
    action: UpdateAssetAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.assetAttachmentClient
      .updateAssetAttachment(
        action.assetId,
        action.attachmentId,
        action.description
      )
      .pipe(
        tap(() => {
          const attachments = ctx.getState().attachments;
          if (!attachments) {
            ctx.patchState({
              attachmentsLoading: false
            });
            return;
          }
          const updatedAttachments = attachments.map((a: AttachmentListItem) =>
            a.id === action.attachmentId
              ? { ...a, description: action.description }
              : a
          );
          ctx.patchState({
            attachments: updatedAttachments,
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(DownloadAssetAttachment)
  downloadAssetAttachment(
    ctx: StateContext<AssetAttachmentStateModel>,
    action: DownloadAssetAttachment
  ) {
    return this.assetAttachmentClient
      .downloadAssetAttachment(action.assetId, action.attachmentId)
      .pipe(
        tap((result) => {
          const aElement = document.createElement('a');
          aElement.setAttribute('download', result.fileName ?? 'file');
          const href = URL.createObjectURL(result.data);
          aElement.href = href;
          aElement.setAttribute('target', '_blank');
          aElement.click();
          URL.revokeObjectURL(href);
          ctx.patchState({
            attachmentsLoading: false
          });
        })
      );
  }

  @Selector()
  static attachments(state: AssetAttachmentStateModel) {
    return state.attachments;
  }

  @Selector()
  static attachmentsLoading(state: AssetAttachmentStateModel) {
    return state.attachmentsLoading;
  }

  private mapFile(file: File) {
    return { data: file, fileName: file.name };
  }
}
