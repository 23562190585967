import { TreeMode } from './treeMode';

export class FetchAsset {
  static readonly type = '[Asset] Fetch asset';

  constructor(public id: string) {}
}

export class ExpandAsset {
  static readonly type = '[Asset] Expand asset';

  constructor(public id: string) {}
}

export class CollapseAsset {
  static readonly type = '[Asset] Collapse asset';

  constructor(public id: string) {}
}

export class ResetAsset {
  static readonly type = '[Asset] Reset asset';
}

export class SetTreeMode {
  static readonly type = '[Asset] Set tree mode';

  constructor(public treeMode: TreeMode) {}
}
