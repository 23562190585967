import { AttachmentListItem } from './attachment-table/attachment-table.component';

export class CreateAssetAttachment {
  static readonly type = '[Asset] Create attachment';

  constructor(
    public assetId: string,
    public file: File,
    public description: string
  ) {}
}

export class RemoveAssetAttachment {
  static readonly type = '[Asset] Remove attachment';

  constructor(
    public assetId: string,
    public attachmentId: number
  ) {}
}

export class UpdateAssetAttachment {
  static readonly type = '[Asset] Update attachment';

  constructor(
    public assetId: string,
    public attachmentId: number,
    public description: string
  ) {}
}

export class DownloadAssetAttachment {
  static readonly type = '[Asset] Download attachment';

  constructor(
    public assetId: string,
    public attachmentId: number
  ) {}
}

export class SetAssetAttachments {
  static readonly type = '[Asset] Set attachments';

  constructor(public attachments: AttachmentListItem[]) {}
}
