import { Action, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { AttachmentListItem } from './attachment-table/attachment-table.component';
import { ApplicationRequirementAttachmentClient } from '../clients/apiClients';
import { tap } from 'rxjs';
import {
  CreateApplicationRequirementAttachment,
  DownloadApplicationRequirementAttachment,
  ListApplicationRequirementAttachments,
  RemoveApplicationRequirementAttachment,
  UpdateApplicationRequirementAttachment
} from './application-requirement-attachments.actions';

export class ApplicationRequirementAttachmentStateModel {
  attachments: AttachmentListItem[] | null = null;
  attachmentsLoading = false;
}

@State<ApplicationRequirementAttachmentStateModel>({
  name: 'applicationRequirementAttachment',
  defaults: {
    attachments: null,
    attachmentsLoading: false
  }
})
@Injectable()
export class ApplicationRequirementAttachmentState {
  applicationRequirementAttachmentClient = inject(
    ApplicationRequirementAttachmentClient
  );

  @Action(CreateApplicationRequirementAttachment)
  createApplicationRequirementAttachment(
    ctx: StateContext<ApplicationRequirementAttachmentStateModel>,
    action: CreateApplicationRequirementAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.applicationRequirementAttachmentClient
      .createApplicationRequirementAttachment(
        action.applicationRequirementId,
        action.description,
        this.mapFile(action.file)
      )
      .pipe(
        tap((createdAttachment) => {
          const attachments = ctx.getState().attachments;
          if (!attachments) {
            ctx.patchState({
              attachmentsLoading: false
            });
            return;
          }
          ctx.patchState({
            attachments: [...attachments, createdAttachment],
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(ListApplicationRequirementAttachments)
  listAttachments(
    ctx: StateContext<ApplicationRequirementAttachmentStateModel>,
    action: ListApplicationRequirementAttachments
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.applicationRequirementAttachmentClient
      .listApplicationRequirementAttachments(action.applicationRequirementId)
      .pipe(
        tap((attachments) => {
          ctx.patchState({
            attachments: attachments,
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(RemoveApplicationRequirementAttachment)
  removeApplicationRequirementAttachment(
    ctx: StateContext<ApplicationRequirementAttachmentStateModel>,
    action: RemoveApplicationRequirementAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.applicationRequirementAttachmentClient
      .deleteApplicationRequirementAttachment(
        action.applicationRequirementId,
        action.attachmentId
      )
      .pipe(
        tap(() => {
          const attachments = ctx.getState().attachments;
          if (!attachments) {
            ctx.patchState({
              attachmentsLoading: false
            });
            return;
          }
          const updatedAttachments = attachments.filter(
            (a: AttachmentListItem) => a.id !== action.attachmentId
          );
          ctx.patchState({
            attachments: updatedAttachments,
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(UpdateApplicationRequirementAttachment)
  updateApplicationRequirementAttachment(
    ctx: StateContext<ApplicationRequirementAttachmentStateModel>,
    action: UpdateApplicationRequirementAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.applicationRequirementAttachmentClient
      .updateApplicationRequirementAttachment(
        action.applicationRequirementId,
        action.attachmentId,
        action.description
      )
      .pipe(
        tap(() => {
          const attachments = ctx.getState().attachments;
          if (!attachments) {
            ctx.patchState({
              attachmentsLoading: false
            });
            return;
          }
          const updatedAttachments = attachments.map((a: AttachmentListItem) =>
            a.id === action.attachmentId
              ? { ...a, description: action.description }
              : a
          );
          ctx.patchState({
            attachments: updatedAttachments,
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(DownloadApplicationRequirementAttachment)
  downloadApplicationRequirementAttachment(
    ctx: StateContext<ApplicationRequirementAttachmentStateModel>,
    action: DownloadApplicationRequirementAttachment
  ) {
    return this.applicationRequirementAttachmentClient
      .downloadApplicationRequirementAttachment(
        action.applicationRequirementId,
        action.attachmentId
      )
      .pipe(
        tap((result) => {
          const aElement = document.createElement('a');
          aElement.setAttribute('download', result.fileName ?? 'file');
          const href = URL.createObjectURL(result.data);
          aElement.href = href;
          aElement.setAttribute('target', '_blank');
          aElement.click();
          URL.revokeObjectURL(href);
          ctx.patchState({
            attachmentsLoading: false
          });
        })
      );
  }

  @Selector()
  static attachments(state: ApplicationRequirementAttachmentStateModel) {
    return state.attachments;
  }

  @Selector()
  static attachmentsLoading(state: ApplicationRequirementAttachmentStateModel) {
    return state.attachmentsLoading;
  }

  private mapFile(file: File) {
    return { data: file, fileName: file.name };
  }
}
