import { Injectable, inject } from '@angular/core';
import {
  AssetImportClient,
  ImportAssetsCommand
} from '../../../clients/apiClients';
import { AssetService } from '../../asset/asset.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportAssetsService {
  private importClient = inject(AssetImportClient);
  private assetService = inject(AssetService);

  async importFile(functionTypeId: number | undefined, file: File) {
    return await firstValueFrom(
      this.importClient.importFile(functionTypeId, this.mapFile(file))
    );
  }

  getImportedAssetSet(id: number) {
    return this.importClient.getImportedAssetSet(id);
  }

  getImportedAssetSets() {
    return this.importClient.getImportedAssetSets();
  }

  removeSet(id: number) {
    return this.importClient.removeSet(id);
  }

  mapFile(file: File) {
    return { data: file, fileName: file.name };
  }

  async import(
    parentId: string,
    setId: number,
    setRowIds: number[]
  ): Promise<number[]> {
    const result = await firstValueFrom(
      this.importClient.import(
        ImportAssetsCommand.fromJS({
          parentId: parentId,
          setId: setId,
          setRowIds: setRowIds
        })
      )
    );

    result.createdAssets.forEach((o) => this.assetService.assetAdded$.emit(o));
    return result.importedAssetRowIds;
  }
}
