import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import { Subject, tap } from 'rxjs';
import {
  ApplicationRequirementTypeClient,
  ApplicationRequirementTypeResult,
  AssetFunctionTypeClient,
  AssetFunctionTypeResult
} from '../clients/apiClients';
import {
  FetchApplicationRequirementTypes,
  FetchFunctionTypes,
  ResetApplicationRequirementTypes,
  ResetFunctionTypes
} from './master-data.actions';

export class MasterDataStateModel {
  functionTypes: AssetFunctionTypeResult[] | null = null;
  applicationRequirementTypes: ApplicationRequirementTypeResult[] | null = null;
}

@State<MasterDataStateModel>({
  name: 'masterData',
  defaults: {
    functionTypes: null,
    applicationRequirementTypes: null
  }
})
@Injectable()
export class MasterDataState {
  private functionTypeClient = inject(AssetFunctionTypeClient);
  private applicationRequirementTypeClient = inject(
    ApplicationRequirementTypeClient
  );

  fetchingAssetSubject = new Subject<void>();

  @Action(FetchFunctionTypes)
  fetchFunctionTypes(ctx: StateContext<MasterDataStateModel>) {
    if (ctx.getState().functionTypes !== null) return;

    return this.functionTypeClient.getOptions().pipe(
      tap((val) => {
        ctx.patchState({
          functionTypes: val
        });
      })
    );
  }

  @Action(FetchApplicationRequirementTypes)
  fetchApplicationRequirementTypes(ctx: StateContext<MasterDataStateModel>) {
    if (ctx.getState().applicationRequirementTypes !== null) return;

    return this.applicationRequirementTypeClient.getOptions().pipe(
      tap((val) => {
        ctx.patchState({
          applicationRequirementTypes: val
        });
      })
    );
  }

  @Action(ResetFunctionTypes)
  resetFunctionTypes(ctx: StateContext<MasterDataStateModel>) {
    ctx.patchState({ functionTypes: null });
  }

  @Action(ResetApplicationRequirementTypes)
  resetApplicationRequirementTypes(ctx: StateContext<MasterDataStateModel>) {
    ctx.patchState({ applicationRequirementTypes: null });
  }

  @Selector()
  static functionTypes(state: MasterDataStateModel) {
    return state.functionTypes;
  }

  @Selector()
  static applicationRequirementTypes(state: MasterDataStateModel) {
    return state.applicationRequirementTypes;
  }
}
