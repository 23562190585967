import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AssetResult } from '../clients/apiClients';
import { firstValueFrom, Subject } from 'rxjs';
import { TreeMode } from './treeMode';
import {
  CollapseAsset,
  ExpandAsset,
  FetchAsset,
  ResetAsset,
  SetTreeMode
} from './asset.actions';
import { AssetService } from '../app/asset/asset.service';

export class AssetStateModel {
  selectedAsset?: AssetResult;
  treeMode: TreeMode = TreeMode.Navigation;
  expandedAssets: string[] = [];
}

@State<AssetStateModel>({
  name: 'asset',
  defaults: {
    treeMode: TreeMode.Navigation,
    expandedAssets: []
  }
})
@Injectable()
export class AssetState {
  private assetService = inject(AssetService);

  fetchingAssetSubject = new Subject<void>();

  @Action(FetchAsset)
  async fetchAsset(ctx: StateContext<AssetStateModel>, action: FetchAsset) {
    this.fetchingAssetSubject.next();
    ctx.patchState({ selectedAsset: undefined });
    const asset = await firstValueFrom(
      this.assetService.getAsset(action.id, this.fetchingAssetSubject)
    );
    ctx.patchState({ selectedAsset: asset });
  }

  @Action(ExpandAsset)
  async expandAsset(ctx: StateContext<AssetStateModel>, action: ExpandAsset) {
    ctx.patchState({
      expandedAssets: [...ctx.getState().expandedAssets, action.id]
    });
  }

  @Action(CollapseAsset)
  async collapseAsset(
    ctx: StateContext<AssetStateModel>,
    action: CollapseAsset
  ) {
    ctx.patchState({
      expandedAssets: ctx
        .getState()
        .expandedAssets.filter((id) => id !== action.id)
    });
  }

  @Action(ResetAsset)
  async resetAsset(
    ctx: StateContext<AssetStateModel>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    action: ResetAsset
  ) {
    ctx.patchState({ selectedAsset: undefined });
  }

  @Action(SetTreeMode)
  async setTreeMode(ctx: StateContext<AssetStateModel>, action: SetTreeMode) {
    ctx.patchState({ treeMode: action.treeMode });
  }

  @Selector()
  static selectedAsset(state: AssetStateModel) {
    return state.selectedAsset;
  }

  @Selector()
  static treeMode(state: AssetStateModel) {
    return state.treeMode;
  }
}
