import { Injectable, inject } from '@angular/core';
import {
  ApplicationRequirementImportClient,
  ImportApplicationRequirementsCommand
} from '../../../clients/apiClients';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportApplicationRequirementsService {
  private importClient = inject(ApplicationRequirementImportClient);

  async importFile(file: File) {
    return await firstValueFrom(
      this.importClient.importFile(this.mapFile(file))
    );
  }

  getImportedSets() {
    return this.importClient.getImportedSets();
  }

  removeSet(id: number) {
    return this.importClient.removeSet(id);
  }

  mapFile(file: File) {
    return { data: file, fileName: file.name };
  }

  async import(assetId: string, setId: number, setRowIds: number[]) {
    return await firstValueFrom(
      this.importClient.import(
        ImportApplicationRequirementsCommand.fromJS({
          assetId: assetId,
          setId: setId,
          setRowIds: setRowIds
        })
      )
    );
  }

  getImportedSet(id: number) {
    return this.importClient.getImportedSet(id);
  }
}
