export class CreateApplicationRequirementAttachment {
  static readonly type = '[ApplicationRequirement] Create attachment';

  constructor(
    public applicationRequirementId: number,
    public file: File,
    public description: string
  ) {}
}

export class RemoveApplicationRequirementAttachment {
  static readonly type = '[ApplicationRequirement] Remove attachment';

  constructor(
    public applicationRequirementId: number,
    public attachmentId: number
  ) {}
}

export class UpdateApplicationRequirementAttachment {
  static readonly type = '[ApplicationRequirement] Update attachment';

  constructor(
    public applicationRequirementId: number,
    public attachmentId: number,
    public description: string
  ) {}
}

export class DownloadApplicationRequirementAttachment {
  static readonly type = '[ApplicationRequirement] Download attachment';

  constructor(
    public applicationRequirementId: number,
    public attachmentId: number
  ) {}
}

export class ListApplicationRequirementAttachments {
  static readonly type = '[ApplicationRequirement] List attachments';

  constructor(public applicationRequirementId: number) {}
}
